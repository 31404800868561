import React from 'react'
import styled from 'styled-components'

import { device } from '../../../device-sizes'

const ServiceCard = ({ icon, iconColorRGBA, title, children }) => {

    const Card = styled.div`
        width: 100%;
        background-color: #403E56;
        border-radius: 15px;

        @media ${device.mobileS} {
            padding: 25px;
        }
    `   
    const IconContainer = styled.div`
        border-radius: 50%;
        background-color: ${iconColorRGBA};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: max-content;
        margin-bottom: 25px;

        @media ${device.mobileS} {
            padding: 20px;
            margin-left: auto;
            margin-right: auto;
        }

        @media ${device.tablet} {
            margin-left: unset;
        }
    `
    
    const Icon = styled.div`
        @media ${device.mobileS} {
            width: 45px;
            height: 45px;
        }

    `
    
    const ServiceTitle = styled.h3`
        color: #ffffff;
        font-weight: bold;
        font-family: Poppins, sans-serif;
        width: 100%;
        margin: 0px 0px 15px 0px;

        @media ${device.mobileS} {
            font-size: 20px;
            text-align: center;
        }

        @media ${device.tablet} {
            text-align: left;
        }
    `
    
    const ServiceCopy = styled.p`
        color: #ffffff;
        font-family: Roboto, sans-serif;
        line-height: 1.7;
        margin: 0px;

        @media ${device.mobileS} {
            font-size: 16px;
            text-align: center;
        }

        @media ${device.tablet} {
            text-align: left;
        }

        @media ${device.desktop} {
            text-align: left;
        }
    `

    return (
        <Card>
            
            <IconContainer style={{backgroundColor: iconColorRGBA}}>
                <Icon>
                    {icon}
                </Icon>
            </IconContainer>

            <ServiceTitle>
                {title}
            </ServiceTitle>

            <ServiceCopy>
                {children}
            </ServiceCopy>
        
        </Card>
    )

}

export default ServiceCard