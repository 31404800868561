import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import IndexHeader from '../sections/IndexHeader'
import TeamFitSection from '../sections/TeamFitSection'
import ServicesSection from '../sections/ServicesSection'
import SkillsSection from '../sections/SkillsSection'

const IndexPage = () => (
  <Layout>
    <SEO title="About"
      description="Technology has and will continue taking over commerce. Take me into your team to implement solutions for web, mobile or desktop using modern scalable strategies."
      lang="EN"
    />

    <IndexHeader />
    <TeamFitSection />
    <ServicesSection />
    <SkillsSection />

  </Layout>
)

export default IndexPage
