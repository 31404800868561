import React from 'react'
import styled from 'styled-components'

// Sizes for media queries
import { device } from '../../device-sizes'

const IndexSection = ({backgroundColor, children}) => {

    //background-color: ${backgroundColor};
    const Section = styled.section`
        

        @media ${device.mobileS} {
            padding: 60px 25px;
        }

        @media ${device.tablet} {
            padding: 120px 50px;
        }

        @media ${device.laptop} {
            padding: 140px 90px;
        }
        
        @media ${device.laptopL} {
            padding: 160px 180px;
        }

        @media ${device.desktop} {
            padding: 180px 200px;
        }

    `

    return(
        <Section style={{backgroundColor: backgroundColor}}>
            {children}
        </Section>
    )
}

export default IndexSection