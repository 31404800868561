import React from 'react'
import styled from 'styled-components'

// Sizes for media queries
import { device } from '../../../device-sizes'

const SectionTitle = ({ color, children }) => {

    const Title = styled.h2`
        color: ${color};
        font-family: Poppins, sans-serif;
        margin: 0px;
        width: 100%;

        @media ${device.mobileS} {
            font-size: 26px;
        }

        @media ${device.mobileL} {
            font-size: 28px;
            text-align: center;
        }

        @media ${device.tablet} {
            font-size: 32px;
            text-align: left;
        }

        @media ${device.laptop} {
            font-size: 36px;
        }

        @media ${device.laptopL} {
            font-size: 42px;
        }

        @media ${device.desktop} {
            font-size: 50px;
        }
    `

    return (
        <Title style={{color: color}}>
            {children}
        </Title>
    )

}

export default SectionTitle