import React from 'react'
import styled from 'styled-components'

import IndexSection from '../components/layouts/SectionLayout'
import SectionTitle from '../components/IndexPage/IndexTypography/Title'
import SectionCopy from '../components/IndexPage/IndexTypography/Copy'

import TeamFitGraphic from '../assets/graphics/TeamFitGraphic.svg'

// Sizes for media queries
import { device } from '../device-sizes'


const TeamFitSection = () => {
    
    const Grid = styled.section`
        display: grid;
        height: max-content;

        @media ${device.mobileS} {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas: 
                "graphic"
                "title"
                "copy";
        }

        @media ${device.tablet} {
            grid-template-areas: 
                "title graphic"
                "copy graphic"
        }
    `

    const Graphic = styled.div`
        grid-area: graphic;
        margin: 0px auto 25px auto;

        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;


        @media ${device.mobileS} {
            height: 225px;
            width: 225px;
        }

        @media ${device.mobileM} {
            height: 240px;
            widht: 240px;
        }

        @media ${device.mobileL} {
            height: 260px;
            width: 260px;
        }

        @media ${device.tablet} {
            margin-left: 60px;
            margin-bottom: 0px;
            width: 300px;
            height: 300px;
        }

        @media ${device.laptop} {
            width: 375px;
            height: 375px;
        }

        @media ${device.laptopL} {
            margin-left: 80px;
            width: 430px;
            height: 430px;
        }

        @media ${device.desktop} {
            width: 460px;
            height: 460px;
        }
    `
    
    const GridTitle = styled.div`
        grid-area: title;
        display: flex;
        align-items: flex-end;
    `
    
    const GridCopy = styled.div`
        grid-area: copy;
        align-items: flex-start;
    `

    return (
        <IndexSection backgroundColor="#FFFFFF">

            <Grid>

                <Graphic>
                    <TeamFitGraphic style={{width: "100%", height: "100%"}} />
                </Graphic>
                
                <GridTitle>
                    <SectionTitle color="#2bbfa7">Every Team Needs Strong Players</SectionTitle>
                </GridTitle>

                <GridCopy>
                    <SectionCopy color="#6E6D7A">
                        Have someone like myself who is confident, willing to learn and grow the business.
                        Flying solo doesn't always work out well, so I always try to strategize in teams
                        to solve impactful problems.
                    </SectionCopy>
                </GridCopy>

            </Grid>

        </IndexSection>
    )

}

export default TeamFitSection;