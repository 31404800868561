import React from 'react'
import styled from 'styled-components'

// Sizes for media queries
import { device } from '../device-sizes'

import IndexSection from '../components/layouts/SectionLayout'
import ServiceCard from '../components/IndexPage/Cards/ServiceCard'
import SectionTitle from '../components/IndexPage/IndexTypography/Title'
import SectionCopy from '../components/IndexPage/IndexTypography/Copy'

const StackIcon = (
    <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.21179 14.4548L23.9598 24.7742C24.6239 25.0759 25.3749 25.0759 26.0389 24.7742L48.787 14.4548C50.4032 13.7214 50.4032 11.279 48.787 10.5456L26.0399 0.225338C25.7136 0.0762083 25.3591 -0.000976562 25.0004 -0.000976562C24.6416 -0.000976562 24.2871 0.0762083 23.9608 0.225338L1.21179 10.5447C-0.404419 11.2781 -0.404419 13.7214 1.21179 14.4548ZM48.788 23.0759L43.1151 20.5046L27.33 27.6599C26.5917 27.9949 25.8075 28.1648 24.9999 28.1648C24.1923 28.1648 23.4091 27.9949 22.6698 27.6599L6.88562 20.5046L1.21179 23.0759C-0.404419 23.8083 -0.404419 26.2498 1.21179 26.9822L23.9598 37.2937C24.6239 37.5945 25.3749 37.5945 26.0389 37.2937L48.788 26.9822C50.4042 26.2498 50.4042 23.8083 48.788 23.0759ZM48.788 35.5564L43.1366 32.9949L27.33 40.1599C26.5917 40.4949 25.8075 40.6648 24.9999 40.6648C24.1923 40.6648 23.4091 40.4949 22.6698 40.1599L6.86414 32.9949L1.21179 35.5564C-0.404419 36.2888 -0.404419 38.7302 1.21179 39.4626L23.9598 49.7742C24.6239 50.0749 25.3749 50.0749 26.0389 49.7742L48.788 39.4626C50.4042 38.7302 50.4042 36.2888 48.788 35.5564Z" fill="#3E7FFD"/>
    </svg>
)

const MicroserviceIcon = (
    <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.3333 33.3333H0V50H33.3333V33.3333Z" fill="#38C17C"/>
        <path d="M16.6667 0H0V16.6667H16.6667V0Z" fill="#38C17C"/>
        <path d="M50 16.6667H33.3334V33.3334H50V16.6667Z" fill="#38C17C"/>
    </svg>
)

const ReportIcon = (
    <svg width="100%" height="100%" viewBox="0 0 38 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
        <path d="M28.125 25.236H9.375V31.5065H28.125V25.236ZM36.8164 10.4416L27.2559 0.839888C26.8164 0.398994 26.2207 0.154053 25.5957 0.154053H25V12.695H37.5V12.0974C37.5 11.4801 37.2559 10.8825 36.8164 10.4416ZM21.875 13.4788V0.154053H2.34375C1.04492 0.154053 0 1.2024 0 2.50549V47.9666C0 49.2696 1.04492 50.318 2.34375 50.318H35.1562C36.4551 50.318 37.5 49.2696 37.5 47.9666V15.8303H24.2188C22.9297 15.8303 21.875 14.7721 21.875 13.4788ZM6.25 7.20836C6.25 6.7753 6.59961 6.42454 7.03125 6.42454H14.8438C15.2754 6.42454 15.625 6.7753 15.625 7.20836V8.77598C15.625 9.20903 15.2754 9.55979 14.8438 9.55979H7.03125C6.59961 9.55979 6.25 9.20903 6.25 8.77598V7.20836ZM6.25 13.4788C6.25 13.0458 6.59961 12.695 7.03125 12.695H14.8438C15.2754 12.695 15.625 13.0458 15.625 13.4788V15.0465C15.625 15.4795 15.2754 15.8303 14.8438 15.8303H7.03125C6.59961 15.8303 6.25 15.4795 6.25 15.0465V13.4788ZM31.25 43.2637C31.25 43.6967 30.9004 44.0475 30.4688 44.0475H22.6562C22.2246 44.0475 21.875 43.6967 21.875 43.2637V41.6961C21.875 41.263 22.2246 40.9122 22.6562 40.9122H30.4688C30.9004 40.9122 31.25 41.263 31.25 41.6961V43.2637ZM31.25 23.6684V33.0741C31.25 33.9402 30.5508 34.6418 29.6875 34.6418H7.8125C6.94922 34.6418 6.25 33.9402 6.25 33.0741V23.6684C6.25 22.8023 6.94922 22.1008 7.8125 22.1008H29.6875C30.5508 22.1008 31.25 22.8023 31.25 23.6684Z" fill="#BD47C6"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="100%" height="50" transform="translate(0 0.154053)"/>
        </clipPath>
        </defs>
    </svg>
)

const CloudIcon = (
    <svg width="100%" height="100%" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path fill="#F7A31C" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4z">
        </path>
    </svg>
)

const ServicesSection = () => {

    const ContentGrid = styled.div`
        display: grid;

        @media ${device.mobileS} {
            grid-template-columns: 1fr;
            grid-template-areas: 
                "copy"
                "cards";
        }

        @media ${device.laptopL} {
            grid-template-columns: 620px calc(100% - 620px - 60px);
            grid-template-areas: "cards copy";
            grid-gap: 60px;
        }

        @media ${device.desktop} {
            grid-gap: 100px;
        }
    `

    const CardGrid = styled.div`
        display: grid;
        width: 100%;
        grid-template-rows: auto;
        grid-area: cards;

        @media ${device.mobileS} {
            grid-gap: 25px;
            grid-template-columns: 1fr;
        }

        @media ${device.mobileL} {
            padding: 0px 50px;
        }

        @media ${device.tablet} {
            padding: 0px;
            grid-template-columns: 1fr 1fr;
            max-width: 670px;
            margin: 0 auto;
        }
    `
    
    const CopyContainer = styled.div`
        grid-area: copy;

        @media ${device.mobileS} {
            margin-bottom: 50px;
        }

        @media ${device.laptopL} {
            margin-bottom: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    `

    return (
        <IndexSection backgroundColor="#302E43">

            <ContentGrid>

                <CopyContainer>

                    <SectionTitle color="#ffffff">
                        Leverage My Knowledge And Passion
                    </SectionTitle>

                    <SectionCopy color="#ffffff">
                        I cover a wide range of roles that a project will face during its 
                        development life cycle. When things get messy, you need someone 
                        like me who wants to be in the thick of it.
                    </SectionCopy>

                </CopyContainer>

                <CardGrid>
                    <ServiceCard icon={StackIcon} iconColorRGBA="rgba(62, 127, 253, 0.25)" title="Full Stack Development">
                        Build every part of an application from front to back, for both web and mobile using modern design practices.
                    </ServiceCard>
                    <ServiceCard icon={MicroserviceIcon} iconColorRGBA="rgba(56, 193, 124, 0.25)" title="Microservices">
                        Engineer backend systems to perform better at scale through demand-based allocation and logic decoupling.
                    </ServiceCard>
                    <ServiceCard icon={ReportIcon} iconColorRGBA="rgba(189, 71, 198, 0.25)" title="Business Analysis">
                        Breakdown workflows of clients and different business operations to figure out positive solutions at scale.
                    </ServiceCard>
                    <ServiceCard icon={CloudIcon} iconColorRGBA="rgba(247, 163, 28, 0.25)" title="Cloud DevOps">
                        Get things up and running on the cloud may it be a simple website or a multi-tier application for consumers.
                    </ServiceCard>
                </CardGrid>

            </ContentGrid>

        </IndexSection>
    )

}

export default ServicesSection