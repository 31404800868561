import React from 'react'
import styled from 'styled-components'

// Sizes for media queries
import { device } from '../../../device-sizes'

const SectionCopy = ({ color, children }) => {

    const Copy = styled.p`
        color: ${color};
        font-family: Roboto, sans-serif;
        margin: 0px;
        width: 100%;
        line-height: 1.5;

        @media ${device.mobileS} {
            margin-top: 20px;
            font-size: 18px;
        }

        @media ${device.mobileL} {
            font-size: 19px;
            text-align: center;
        }

        @media ${device.tablet} {
            font-size: 20px;
            text-align: left;
        }

        @media ${device.laptop} {
            font-size: 22px;
        }

        @media ${device.laptopL} {
            font-size: 24px;
        }

        @media ${device.desktop} {
            font-size: 28px;
        }
    `

    return (
        <Copy style={{color: color}}>
            {children}
        </Copy>
    )

}

export default SectionCopy